import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { createRoot } from 'react-dom/client';
import AddressAutocompleteForm from './address_autocomplete_form';

function AddressAutocomplete() {
  const [country, setCountry] = useState('');

  useEffect(() => {
    $('.address-form .spinner').remove();
  }, []);
  
  const handleCountryChange = (event) => {
    if (event.length === 0) { 
      setCountry('');
    } else {
      setCountry(event?.value);
      document.getElementById('redemption_address_form_country').value = event?.value;
    }
  };

  useEffect(() => {
    if (country === '') { return; }
    document.getElementById('redemption_address_form_address_2').classList.remove('d-none');
    document.getElementById('redemption_address_form_city').classList.remove('d-none');
    document.getElementById('redemption_address_form_state').classList.remove('d-none');
    document.getElementById('redemption_address_form_zip').classList.remove('d-none');
    $(".new_redemption_address_form button").removeClass('btn-secondary').addClass('btn-primary').prop("disabled", false);
  }, [country]);

  return (
    <div>
      <Select
        id="country-select"
        options={gon.countries}
        onChange={handleCountryChange}
        className="mb-1"
        placeholder={gon.select_country}
        classNames={{ // for dark mode
          control: (state) => 'react-select-control',
          menu: (state) => 'react-select-menu',
          option: (state) => `react-select-menu-item ${state.isFocused ? 'focused' : ''}`,
        }}
      />
      {country && <AddressAutocompleteForm country={country} />}
    </div>
  );
}

export default AddressAutocomplete;

const addressElement = document.getElementById('address-autocomplete');
if (addressElement) { // this is relevant for specific catalogs only, like Amazon
  const root = createRoot(addressElement);
  root.render(
    <React.StrictMode>
      <AddressAutocomplete />
    </React.StrictMode>
  );
}

